import React, { useEffect, useState } from 'react';
import './css/Product.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { arrayRemove, arrayUnion, doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../../Admin/Config/Config';
import Loading from '../../assets/Loading/Loading';
import { useRelatedProducts } from './RelatedProducts/RelatedProducts';
import { useLoginPopup } from '../../Auth/LoginContext';
import { useCartContext } from './CartContext';
import { useToast } from '../../Toast.jsx/Toast';
export default function View() {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const {showWarning} = useToast();
  const navigate = useNavigate();
  const { relatedProducts, fetchRelatedProductsByIds } = useRelatedProducts();
    const { openPopup } = useLoginPopup();

  const [mainImage, setMainImage] = useState(
    "https://lh5.googleusercontent.com/proxy/t08n2HuxPfw8OpbutGWjekHAgxfPFv-pZZ5_-uTfhEGK8B5Lp-VN4VjrdxKtr8acgJA93S14m9NdELzjafFfy13b68pQ7zzDiAmn4Xg8LvsTw1jogn_7wStYeOx7ojx5h63Gliw"
  );
  const handleThumbnailClick = (image) => {
    setMainImage(image);
  };
  const [product, setProduct] = useState({});
  const fetchProduct = async (query) => {
    const docSnap = await getDoc(doc(db, "products", query));
    if (docSnap.exists()) {
      if (docSnap.data().relatedProducts) {
        await fetchRelatedProductsByIds(docSnap.data().relatedProducts);
      }
      const img = new Image();
      setProduct({...docSnap.data(), id: docSnap.id});
      setMainImage(docSnap.data().product_images[0]);
      img.src = docSnap.data().product_images[0];
      img.onload = () => {
        setLoading(false);
      };
    }
  };

  useEffect(() => {
    setLoading(true);
    const params = new URLSearchParams(location.search);
    const queryParam = params.get("query");
    if (queryParam) {
      fetchProduct(queryParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // Helper function to generate star ratings
  const renderStars = (rating) => {
    const totalStars = 5;
    return Array.from({ length: totalStars }, (_, index) => (
      <span
        key={index}
        className={index < rating ? "text-yellow-500" : "text-gray-300"}
      >
        ★
      </span>
    ));
  };

  const handleDivClick = async (id) => {
    navigate(`/view?query=${encodeURIComponent(id)}`);
  };
  // ========================= Save to whishlist ========================= //
  const toggleLike = async (e, id, liked) => {
    e.stopPropagation();
    console.log(liked)
    if (auth.currentUser) {
      const userId = auth.currentUser.uid;
        console.log(product.liked_users)
        await updateDoc(doc(db, "products", id), {
        liked_users: liked ? arrayRemove(userId) : arrayUnion(userId),
      });

      await fetchRelatedProductsByIds(product.relatedProducts);
    } else {
      openPopup();
    }
  };

  // ========================= Add to cart ========================= //
     const { addToCart } = useCartContext();  
     const handleAddToCart = async (e, pro) => {
       e.stopPropagation();
       if(pro.stock>=1){
       if (!auth.currentUser) {
         await addToCart("", pro);
       } else {
         await addToCart(auth.currentUser.uid, pro);
       }
      } else {
        showWarning("Out of stock");
      }
     };
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="font-sans">
          <div className="p-4 py-12 px-6 max-w-8xl mx-auto">
            <div className="grid items-start grid-cols-1 lg:grid-cols-2 gap-6 max-lg:gap-12">
              <div className="w-full lg:sticky top-0 sm:flex gap-2">
                <div className="sm:space-y-3 w-16 max-sm:w-12 max-sm:flex max-sm:mb-4 max-sm:gap-4">
                  {product?.product_images?.length > 0 ? (
                    product.product_images.map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        alt={`Product ${index + 1}`}
                        className={`w-full px-1 py-1 cursor-pointer rounded-md ${
                          mainImage === image
                            ? "border border-green-800 border-1 border-dotted"
                            : ""
                        }`}
                        onClick={() => handleThumbnailClick(image)}
                      />
                    ))
                  ) : (
                    <p>No images available</p>
                  )}
                </div>
                <div className="flex justify-center items-center w-full">
                  <img
                    src={mainImage}
                    alt="Product"
                    className="w-4/5 border border-gray-400 border-dotted py-4 px-12 rounded-md object-cover"
                  />
                </div>
              </div>

              <button className="bg-[#1a572b] text-white py-2  px-3 h-6 rounded-full flex items-center justify-center transition duration-300 absolute top-1 left-1">
                In Stock
              </button>

              <div>
                <h2 className="text-[24px] font-bold text-gray-800">
                  {product.product_name}
                </h2>
                <p className="text-gray-500 text-[14px] mt-2">
                  {product.brand}
                </p>
                <div className="flex flex-wrap gap-4 mt-4 items-center">
                  <h1 className="text-[36px] text-gray-800 font-bold">
                    ${product.offer_price}
                  </h1>
                  <div className="flex items-center">
                    {product.tax_applicable && (
                      <span className="text-xs text-gray-600">
                        &ensp;(HST){" "}
                      </span>
                    )}
                     <div>
                  <p className="text-[16px] text-gray-600 mt-2 strike-price">
                    <p>
                      
                      <span>
                        {" "}
                        <strike className="text-red-500 ml-1">
                          ${product.original_price}
                        </strike>
                      </span>
                    </p>
                  </p>
                </div>
                  </div>
                </div>
               
                <p className="text-gray-500 text-[14px] mt-2">
                  Qty : {product.quantity} {product.measure_unit}
                </p>

                <div className="flex space-x-2 mt-4">
                  {Array.from({ length: 5 }, (_, index) =>
                    index < product.rating ? (
                      <svg
                        key={index}
                        className={`w-5 fill-[#f4bb00]`}
                        viewBox="0 0 14 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M7 0L9.4687 3.60213L13.6574 4.83688L10.9944 8.29787L11.1145 12.6631L7 11.2L2.8855 12.6631L3.00556 8.29787L0.342604 4.83688L4.5313 3.60213L7 0Z" />
                      </svg>
                    ) : (
                      <svg
                        key={index}
                        className="w-5 fill-[#CED5D8]"
                        viewBox="0 0 14 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M7 0L9.4687 3.60213L13.6574 4.83688L10.9944 8.29787L11.1145 12.6631L7 11.2L2.8855 12.6631L3.00556 8.29787L0.342604 4.83688L4.5313 3.60213L7 0Z" />
                      </svg>
                    )
                  )}
                </div>

                <button
                  onClick={(e) => handleAddToCart(e, product)}
                  type="button"
                  className="w-full mt-8 px-6 py-3 bg-[#1a572b] hover:bg-green-700 text-white text-sm font-semibold rounded-md"
                >
                  Add to cart
                </button>

                <div className="mt-8">
                  {product && product.specifications?.length > 0
                    ? product.specifications.map((item, index) => (
                        <div key={index}>
                          <h3 className="text-xl font-bold  text-gray-800">
                            Specifications
                          </h3>
                          <p className="text-gray-600 first-letter:capitalize text-sm mt-4">
                            {item.field_value}
                          </p>
                        </div>
                      ))
                    : null}
                  <h3 className="text-xl mt-4 font-bold text-gray-800">
                    About the item
                  </h3>
                  <ul className="space-y-3 list-disc mt-4 pl-4 text-sm text-gray-800">
                    {product?.about_product?.length > 0 ? (
                      product.about_product.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))
                    ) : (
                      <p>No information available</p>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            {relatedProducts && relatedProducts.length > 0 && (
              <div className=" my-12">
                <h1 className="text-xl sm:px-2 px-4 text-gray-700 font-bold  mb-6">
                  Related Products
                </h1>
                <div className="mx-auto max-w-8xl lg:px-8 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-2">
                  {relatedProducts &&
                    relatedProducts.map((product) => {
                      const liked = product.liked_users?.includes(
                        auth.currentUser?.uid
                      );
                      return (
                        <div
                          key={product.id}
                          onClick={(e) => {
                            handleDivClick(product.id);
                          }}
                          className="bg-white p-4 border border-gray-400 border-dotted rounded-lg text-left relative cursor-pointer"
                        >
                          <img
                            src={product.thumb_url}
                            alt={product.product_name}
                            className="w-full h-44 object-contain mb-4 rounded"
                          />
                          <button
                            onClick={(e) => toggleLike(e, product.id, liked)}
                            className={`rounded-full flex items-center justify-center transition duration-300 absolute top-1 right-1 ${
                              liked ? "" : "bg-gray-100"
                            }`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              className="bi bi-heart"
                              viewBox="0 0 16 16"
                            >
                              {/* Heart shape */}
                              <path
                                d="M8 2.748l-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01z"
                                fill={liked ? "red" : "none"} // Fill red if liked, otherwise transparent
                                stroke={liked ? "none" : "currentColor"} // No stroke if liked, outline if not
                                strokeWidth={liked ? 0 : 1} // Set stroke width for outline
                              />
                            </svg>
                          </button>

                          {product.stock <= 0 ? (
                            <button className="bg-red-500 text-white py-1 px-2 rounded-full absolute top-1 left-1">
                              Out of stock
                            </button>
                          ) : (
                            <>
                              {((product.original_price - product.offer_price) /
                                product.original_price) *
                                100 >=
                              10 ? (
                                <button className="bg-[#1a572b] text-white py-1 px-2 rounded-full absolute top-1 left-1">
                                  {(
                                    ((product.original_price -
                                      product.offer_price) /
                                      product.original_price) *
                                    100
                                  ).toFixed(0)}
                                  % Off
                                </button>
                              ) : null}
                            </>
                          )}

                          <div className="flex items-center justify-between py-2">
                            <h2 className="text-lg font-thin">
                              {product.product_name}
                              {product.tax_applicable && (
                                <span className="text-xs text-gray-600">
                                  &ensp;(HST){" "}
                                </span>
                              )}
                            </h2>
                            <p className="text-[#1a572b] font-medium text-lg">
                              ${product.offer_price}
                            </p>
                          </div>

                          <p className="text-sm py-1 text-gray-500">
                            {product.quantity}&nbsp;
                            <span className="font-light ">
                              {product.measure_unit}
                            </span>
                          </p>
                          <p className="text-[#1a572b] font-medium text-lg">
                            $&nbsp;{product.offer_price.toFixed(2)}&nbsp;
                            <span className="text-red-500 font-medium text-xs ml-2 line-through">
                              {product.original_price > product.offer_price
                                ? ` $ ${product.original_price.toFixed(2)}`
                                : null}
                            </span>
                          </p>

                          <div className="flex items-center justify-between">
                            <div className="flex items-center">
                              {renderStars(product.rating)}
                            </div>
                            <button
                              onClick={(e) => handleAddToCart(e, product)}
                              className="bg-[#1a572b] text-white w-8 h-8 rounded-full flex items-center justify-center hover:bg-green-800 transition duration-300"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-cart-plus"
                                viewBox="0 0 16 16"
                              >
                                <path d="M9 5.5a.5.5 0 0 0-1 0V7H6.5a.5.5 0 0 0 0 1H8v1.5a.5.5 0 0 0 1 0V8h1.5a.5.5 0 0 0 0-1H9z" />
                                <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1zm3.915 10L3.102 4h10.796l-1.313 7zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0m7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                              </svg>
                            </button>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
